var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "ejs-grid",
        {
          ref: "health-path-grid",
          attrs: {
            dataSource: _vm.data,
            allowPaging: true,
            allowFiltering: true,
            allowSorting: true,
            actionComplete: _vm.actionComplete,
            toolbar: _vm.toolbarOptions,
            toolbarClick: _vm.toolbarClick,
            sortSettings: _vm.sortOptions,
            filterSettings: _vm.filterOptions,
            searchSettings: _vm.searchOptions,
            pageSettings: _vm.pageSettings,
            commandClick: _vm.commandClick,
            editSettings: _vm.editSettings
          }
        },
        [
          _c(
            "e-columns",
            [
              _c("e-column", {
                attrs: {
                  field: "Id",
                  headerText: _vm.$t("shared.id"),
                  isPrimaryKey: "true",
                  width: "30",
                  visible: false
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "Name",
                  headerText: _vm.$t("healthPath.name"),
                  width: "160"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "AppointmentsCount",
                  headerText: _vm.$t("healthPath.appointmentsNumber"),
                  type: "number",
                  width: "160"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "CompletedAt",
                  headerText: _vm.$t("healthPath.completed"),
                  width: "80",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  type: "boolean"
                }
              }),
              _c("e-column", {
                attrs: {
                  field: "MovedToGymAt",
                  headerText: _vm.$t("healthPath.movedToGym"),
                  width: "80",
                  valueAccessor: _vm.yesNoGridValueMapper,
                  type: "boolean"
                }
              }),
              _c("e-column", { attrs: { width: "60", commands: _vm.commands } })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }